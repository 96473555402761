$(document).ready(function () {

    // Mobile Safari in standalone mode
    if(("standalone" in window.navigator) && window.navigator.standalone){

        // If you want to prevent remote links in standalone web apps opening Mobile Safari, change 'remotes' to true
        var noddy, remotes = false;

        document.addEventListener('click', function(event) {

            noddy = event.target;

            // Bubble up until we hit link or top HTML element. Warning: BODY element is not compulsory so better to stop on HTML
            while(noddy.nodeName !== "A" && noddy.nodeName !== "HTML") {
                noddy = noddy.parentNode;
            }

            if('href' in noddy && noddy.href.indexOf('http') !== -1 && (noddy.href.indexOf(document.location.host) !== -1 || remotes))
            {
                event.preventDefault();
                document.location.href = noddy.href;
            }

        },false);
    }

    $("a[href^='http://']").attr("target","_blank");
    $("a[href$='.pdf']").attr("target","_blank");

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        cache: false
    });
    window.sr = new scrollReveal();

    $('a.gallery').colorbox({
        rel: 'gal',
        scalePhotos: true,
        width: '90%',
        height: '90%',

        current: "{current} / {total}",
        previous: "<i class='fa fa-arrow-left'></i>",
        next: "<i class='fa fa-arrow-right'></i>",
        close: "<i class='fa fa-times'></i>",

    });

    var mySwiper = new Swiper('.swiper-container', {
        direction: 'horizontal',
        loop: true,
        autoplay: 7000,

        // If we need pagination
        pagination: '.swiper-pagination',

        // Navigation arrows
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',

        // And if we need scrollbar
        scrollbar: '.swiper-scrollbar',
    })


    $(window).scroll(function () {
        if ($(document).scrollTop() > 26) {
            $('.navbar').addClass('navbar-fixed-top').css('margin-top','0px');
        } else {
            $('.navbar').removeClass('navbar-fixed-top');
        }
        if ($(document).scrollTop() > 56) {
            $('.navbar').addClass('shrink');
        } else {
            $('.navbar').removeClass('shrink');
        }
    });
    if ($(document).scrollTop() > 26) {
        $('.navbar').addClass('navbar-fixed-top').css('margin-top','0px');
    } else {
        $('.navbar').removeClass('navbar-fixed-top');
    }
    if ($(document).scrollTop() > 56) {
        $('.navbar').addClass('shrink');
    } else {
        $('.navbar').removeClass('shrink');
    }

    //$(window).scroll(function () {
    //    if ($(document).scrollTop() > 26) {
    //        $('.navtop').addClass('shrink');
    //        $('nav').addClass('shrink');
    //    } else {
    //        $('.navtop').removeClass('shrink');
    //        $('nav').removeClass('shrink');
    //    }
    //});
    //

    //$('#carousel-header').carousel({
    //    interval: 7000
    //})
    //// delay while loading
    //$(".carousel-caption").delay(400).fadeIn();
    //$(".carousel-caption-2").delay(600).fadeIn();
    //
    //$('#carousel-header').on('slide.bs.carousel', function () {
    //    $(".carousel-caption").fadeOut();
    //    $(".carousel-caption-2").delay(200).fadeOut();
    //})
    //$('#carousel-header').on('slid.bs.carousel', function () {
    //    $(".carousel-caption").fadeIn();
    //    $(".carousel-caption-2").delay(200).fadeIn();
    //})
    $('.matchheight').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });


    // demoform
    $("#demoformSubmit").on("click", function (e) {
        e.preventDefault();
        var data = $('#demoform').serializeArray();
        //console.log(data);
        var loadUrl = $('#demoform').attr('action');
        $('#demoformSubmit').prop("disabled", true);
        $('#sendState').html('<i class="fa fa-circle-o-notch fa-spin"></i>');

        $.ajax({
            url: loadUrl,
            type: "POST",
            cache: false,
            data: $('#demoform').serializeArray(),

            success: function (response) {
                $('#collapseDemoForm').html('<div class="gap gap-md"></div><h3>' + response.header + '</h3><p>' + response.message + '</p>');
            },

            error: function (data) {
                var errors =  JSON && JSON.parse(data.responseText) || $.parseJSON(data.responseText);//$.parseJSON(data.responseText);

                $.each(errors, function (index, value) {

                    //$('input[name="'+index+'"]').css('border-color', 'red');

                    if ($('input[name="' + index + '"]').next('span').length)
                        $('#' + index).text(value);
                    else
                        $('input[name="' + index + '"]').after("<span id='" + index + "' class='help-block small danger'>" + value + "</span>");
                });
                $('#demoformSubmit').prop("disabled", false);
                $('#sendState').html('');

            }
        });
    });
    // demoform
    $("#contactformSubmit").on("click", function (e) {
        e.preventDefault();
        var data = $('#contactform').serializeArray();
        //console.log(data);
        var loadUrl = $('#contactform').attr('action');

        $('#contactformSubmit').prop("disabled", true);
        $('#sendState').html('<i class="fa fa-circle-o-notch fa-spin"></i>');

        $.ajax({
            url: loadUrl,
            type: "POST",
            cache: false,
            data: $('#contactform').serializeArray(),

            success: function (response) {
                $('#collapseContactForm').html('<div class="gap gap-md"></div><h3>' + response.header + '</h3><p>' + response.message + '</p>');
            },

            error: function (data) {
                var errors =  JSON && JSON.parse(data.responseText) || $.parseJSON(data.responseText);//$.parseJSON(data.responseText);

                $.each(errors, function (index, value) {

                    //$('input[name="'+index+'"]').css('border-color', 'red');

                    if ($('input[name="' + index + '"]').next('span').length)
                        $('#' + index).text(value);
                    else
                        $('input[name="' + index + '"]').after("<span id='" + index + "' class='help-block small danger'>" + value + "</span>");
                });
                $('#contactformSubmit').prop("disabled", false);
                $('#sendState').html('');

            }

        });
    });


});